import { Component, CUSTOM_ELEMENTS_SCHEMA, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from '../../services/menu.service';
import { UserService } from '../../services/user.service';
import firebase from 'firebase/compat/app';
import { ToastService } from '../../shared/toast.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Accordion } from 'primeng/accordion';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavigationService } from '../../shared/navigation.service';

@Component({
  selector: 'app-outlet-menu',
  templateUrl: './outlet-menu.component.html',
  styleUrl: './outlet-menu.component.scss',
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })), // Initial hidden state
      transition(':enter', [animate('200ms ease-in')]), // Fade in
      transition(':leave', [animate('200ms ease-out')]) // Fade out
    ])
  ]
})

export class OutletMenuComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  showSpinner: boolean = false;
  userNotLoggedIn: boolean = false;
  menuItems: any[] = [];
  originalMenuItems: any[] = [];
  discounts: any[] = [];
  imageLoaded: boolean[] = [];
  accordionItems = [
    { title: 'Text Item', type: 'text', description: 'This is some text content.', selected: true },
    { title: 'Image Item', type: 'image', imageUrl: 'https://via.placeholder.com/150', selected: false },
    { title: 'Another Text Item', type: 'text', description: 'Another text item content.', selected: false }
  ];
  outletDetails: any;
  cartDetails: any;
  location: any;
  locality: any;
  resId: any;
  searchValue: string = '';
  filterVeg: boolean = false;
  filterNonVeg: boolean = false;
  showNoItemsMessage: boolean = false;
  @ViewChild('accordion') accordion!: any;
  cartCount: number = 0;
  showActionPanel: boolean = true;
  startY: any;
  constructor(private router: Router, private menuService: MenuService, private userService: UserService, private route: ActivatedRoute,
    private toastService: ToastService, private afAuth: AngularFireAuth, private fireStore: AngularFirestore, private navigationService: NavigationService) {
    this.getScreenSize();
    this.getDiscounts();
    this.route.queryParams.subscribe(params => {
      this.location = params['l1'];
      this.locality = params['l2'];
      this.resId = params['res'];
    });
    this.getMenuItems();
    let startTime = new Date();
    const timer = setInterval(() => {
      if (this.userService.outletDetails) {
        this.outletDetails = this.userService.outletDetails;
        clearInterval(timer);
      } else {
        
        let elapsedTime = new Date().getTime() - startTime.getTime();
        if (elapsedTime > 300) {
          this.userService.getOutletDetails(this.location, this.locality, this.resId).subscribe((res) => {
            if (res.exists) { // Ensure the document exists
              this.outletDetails = { ...res.data(), id: res.id }; // Merge `id` with document data
              this.userService.outletDetails = this.outletDetails;
            } else {
              console.warn('Document does not exist!');
            }
          });
          
          clearInterval(timer);
        }
      }
    },);
    let startTimer = new Date();
    const timerUser = setInterval(() => {
      if (this.userService.userData) {
        this.getCart();
        clearInterval(timerUser);
      } else {
        let elapsedTime = new Date().getTime() - startTimer.getTime();
        if (elapsedTime > 300) {
          firebase.auth().onAuthStateChanged((user: any) => {
            if (user) {
              this.userService.getCurrentUser(user.uid).subscribe((res: any) => {
                if (res.data()) {
                  if (!res.data()?.hasOwnProperty('firstName')) {
                    console.warn('complete sign up');
                  } else {
                    this.userService.userData = res.data();
                    this.getCart()
                  }
                }
              })
            } else {
              this.userNotLoggedIn = true;
              console.warn("User not logged in");
            }
          });
          clearInterval(timerUser);
        }
      }
    },);
  }

  getDiscounts() {
    this.discounts = [];
    this.userService.getActiveDiscounts().subscribe((res: any) => {
      res.forEach((discount: any) => {
        this.discounts.push(discount.data())
      });
    })
  }

  navigateToHome() {
    this.router.navigateByUrl('home')
  }

  navigateToCart() {
    const queryParams = { activeScreen: 'cart' };
    this.navigationService.lastVisitedPath = window.location.href;
    this.router.navigate(['home'], {
      // relativeTo: this.route, 
      queryParams: queryParams,
      queryParamsHandling: 'merge' // Preserve existing query parameters
    });
  }

  getMenuItems() {
    this.originalMenuItems = [];
    this.menuService.getMenuCategories(this.location, this.locality, this.resId).subscribe((res: any) => {
      res.forEach((menuCategory: any) => {
        const data = menuCategory.data();
        const id = menuCategory.id;
        this.originalMenuItems.push({ ...data, id });
      });
      this.originalMenuItems.sort((a, b) => a.index - b.index);
      this.originalMenuItems.forEach((category) => {
        if (category.isActive) {
          this.menuService.getMenuItems(this.location, this.locality, this.resId, category.id).subscribe((res) => {
            category['menuItems'] = res.docs.map(doc => doc.data());
          })
        }
      })
      this.originalMenuItems.forEach(() => {
        this.imageLoaded.push(false);
      });
      this.menuItems = [...this.originalMenuItems];
    })
  }

  loadImg(index: any) {
    console.warn(index);

  }

  addItemToCart(menuItem: any) {
    this.showSpinner = true;
    var obj = {
      itemName: menuItem.itemName,
      itemPrice: menuItem.itemPrice,
      itemQuantity: 1,
      itemImage: menuItem.itemImageUrl,
      itemClassification: menuItem.itemClassification
    };

    var outletObj = {
      outletName: this.userService.outletDetails.outletName,
      outletLocality: this.userService.outletDetails.outletLocality,
      outletLocation: this.userService.outletDetails.outletLocation,
      outletCoordinates: this.userService.outletDetails.outletLocationValues,
      outletId: this.userService.outletDetails.id
    }
    this.userService.addToCart(outletObj, obj)
      .then(() => {
        this.showSpinner = false;
        this.getCart();
      })
      .catch(error => console.error("Error adding item to cart:", error))

      // var cartObj = {
      //   notificationTitle: 'New item to cart',
      //   notificationDescription: `Error adding item to cart by ${this.outletDetails.outletName}`,
      //   notificationTime: new Date().valueOf(),
      //   severity: "danger"
      // }
      // this.userService.superAdminIds.forEach((admin) => {
      //   this.userService.sendNotification(admin, cartObj).then((res) => {
      //     // console.warn("notification sent");
      //   })
      // })
  }

  getCart() {
    this.cartCount = 0;
    this.userService.getCartDetails().subscribe((res: any) => {
      this.cartDetails = res.data()?.items;
      this.cartDetails.forEach((item: any) => {
        this.cartCount = this.cartCount + (item.itemQuantity)
      });
    })
  }

  shareOutlet() {
    this.showSpinner = true;
    if (navigator.share) {
      navigator
        .share({
          title: `${this.outletDetails.outletName} | Check out this restaurant on AAhaar!`,
          text: 'I found this amazing restaurant on AAhaar. You should check it out too!',
          url: window.location.href,
        })
        .then(() => {
          this.showSpinner = false;
        })
        .catch((error) => {
          this.showSpinner = false;
          console.log('Error sharing', error)
          
        });
    } else {
      this.showSpinner = false;
      this.toastService.showError("Web Share API not supporte")
      console.log('Web Share API not supported');
    }
  }

  isInCart(itemName: string): boolean {
    if (!this.cartDetails) {
      return false;
    }
    return this.cartDetails.some((item: any) => item.itemName === itemName);
  }

  itemQuantityCheck(itemName: any): number {
    if (this.cartDetails.length > 0) {
      const index = this.cartDetails?.findIndex((item: any) => item.itemName === itemName);
      return index != -1 ? this.cartDetails[index]['itemQuantity'] : 0;
    } else {
      return 0;
    }
  }

  changeQuantity(item: any, operator: any) {
    this.showSpinner = true;
    const uid = this.afAuth.authState.subscribe(user => {
      if (user) {
        const cartRef = this.fireStore.collection('users').doc(user.uid).collection('cart').doc('cartItems');

        cartRef.get().subscribe((doc: any) => {
          if (doc.exists) {
            const items = doc.data()?.items || [];
            const itemIndex = items.findIndex((i: any) => i.itemName === item);

            if (itemIndex !== -1) {
              if (operator === 'inc') {
                items[itemIndex].itemQuantity += 1;
              } else if (operator === 'dec') {
                if (items[itemIndex].itemQuantity > 1) {
                  items[itemIndex].itemQuantity -= 1;
                } else {
                  // Remove the item if quantity is 1 and operator is 'dec'
                  items.splice(itemIndex, 1);
                }
              }

              cartRef.update({ items: items });
              this.showSpinner = false;
              this.getCart();
            }
          }
        });
      }
    });
  }

  searchItems() {
    if (this.searchValue.trim() === '') {
      // If search value is empty, reset the menuItems and apply filter
      this.menuItems = [...this.originalMenuItems];
      this.filterMenuItems(); // Apply filter after resetting
      return;
    }

    this.menuItems = this.originalMenuItems.map(category => ({
      ...category,
      menuItems: category.menuItems.filter((item: any) => {
        const isVeg = item.itemClassification === 'Veg';
        const isNonVeg = item.itemClassification === 'Non-Veg';
        const searchTerm = this.searchValue.toLowerCase();

        // Check if itemName or itemDescription contains the search value (case-insensitive)
        const matchesSearch = item.itemName.toLowerCase().includes(searchTerm) ||
          (item.itemDescription && item.itemDescription.toLowerCase().includes(searchTerm));

        // Apply filter based on switch states
        if (this.filterVeg && this.filterNonVeg) {
          return (isVeg || isNonVeg) && matchesSearch; // Show both Veg and Non-Veg if they match the search
        } else if (this.filterVeg) {
          return isVeg && matchesSearch; // Show only Veg if it matches the search
        } else if (this.filterNonVeg) {
          return isNonVeg && matchesSearch; // Show only Non-Veg if it matches the search
        } else {
          return matchesSearch; // Show all items if they match the search
        }
      })
    }));
    const hasItems = this.menuItems.some(category => category.menuItems.length > 0);

    if (!hasItems) {
      this.showNoItemsMessage = true;
    } else {
      this.showNoItemsMessage = false;
    }
  }

  filterMenuItems() {
    this.menuItems = this.originalMenuItems.map(category => ({
      ...category,
      menuItems: category.menuItems.filter((item: any) => {
        const isVeg = item.itemClassification === 'Veg';
        const isNonVeg = item.itemClassification === 'Non-Veg';

        // Apply filter based on switch states
        if (this.filterVeg && this.filterNonVeg) {
          return isVeg || isNonVeg; // Show both Veg and Non-Veg
        } else if (this.filterVeg) {
          return isVeg; // Show only Veg
        } else if (this.filterNonVeg) {
          return isNonVeg; // Show only Non-Veg
        } else {
          return true; // Show all items
        }
      })
    }));
  }

  navigateToLogin() {
    this.router.navigateByUrl('login')
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }

  @HostListener('document:wheel', ['$event'])
  onWheel(event: WheelEvent) {
    const deltaY = event.deltaY; // Get the vertical scroll amount
    if (deltaY > 0 && this.showActionPanel) { // Scrolling down
      this.showActionPanel = false;
    } else if (deltaY < 0 && !this.showActionPanel) { // Scrolling up
      this.showActionPanel = true;
    }
  }

  @HostListener("window:scroll", ['$event'])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    console.log(offset);

    if (offset > 100 && this.showActionPanel) { // Hide when scrolling down
      this.showActionPanel = false;
    } else if (offset <= 100 && !this.showActionPanel) { // Show when scrolling up
      this.showActionPanel = true;
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY; // Record the starting Y position
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent) {
    const currentY = event.touches[0].clientY; // Get the current Y position
    const deltaY = this.startY - currentY; // Calculate the difference

    if (deltaY > 0 && this.showActionPanel) { // Swipe up
      this.showActionPanel = false;
    } else if (deltaY < 0 && !this.showActionPanel) { // Swipe down
      this.showActionPanel = true;
    }
  }


}
