import { ChangeDetectorRef, Component, HostListener, Input, SimpleChanges } from '@angular/core';
import { RazorpayService } from '../../services/razorpay.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AddressBookService } from '../../services/address-book.service';
import firebase from 'firebase/compat/app';
import { ToastService } from '../../shared/toast.service';
import { NavigationService } from '../../shared/navigation.service';

@Component({
  selector: 'app-preview-cart',
  templateUrl: './preview-cart.component.html',
  styleUrl: './preview-cart.component.scss'
})
export class PreviewCartComponent {

  showCartCount: boolean = false;
  showChickenCartCount: boolean = false;
  showContainer: boolean = false;
  showAddSection: boolean = false;
  visible: boolean = false;
  @Input() userData: any;
  showSpinner: boolean = false;
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  cartDetails: any;
  cartSubTotal: number = 0;
  gstCharges: number = 0;
  cartTotal: number = 0;
  distanceInKm: number = 0;
  deliveryPartnerFee: number = 0;
  origin: any;
  destination: any;
  travelTime: any;
  duration: any;
  adpTime: any;
  travelDistance: any;
  center: google.maps.LatLngLiteral = { lat: 24, lng: 12 };
  localityName: string | null = null;
  cityName: any;
  userAddress: any;
  discounts: any[] = [];
  savedAddress: any[] = [];
  foundNearbyAddress: boolean = false;
  constructor(private razorpayService: RazorpayService, private router: Router, private userService: UserService, 
    private route: ActivatedRoute, private afAuth: AngularFireAuth, private fireStore: AngularFirestore,
    private addressBookService: AddressBookService, private cdr: ChangeDetectorRef, private toastService: ToastService,
    private navigationService: NavigationService) {
    this.getScreenSize();
    this.getDiscounts();
    const mobileBodyDiv = document.querySelector('.mobileBody');
    if (mobileBodyDiv) {
      mobileBodyDiv.classList.add('greyBg');
    }
    // this.showSpinner = true;
    let startTimer = new Date();
    const timerUser = setInterval(() => {
      if(this.userService.userData) {
        if(!this.userService.userAddress) {
          this.getCartDetails();
          this.getSavedAddresses();
        } else {
          this.userAddress = this.userService.userAddress
          this.getCartDetails();
          if(!this.userService.duration) {
            var timer = setInterval(() => {
              if(this.origin) {
                clearInterval(timer)
                this.destination = {
                  latitude: this.userService.userAddress.locationCoordinates.lat,
                  longitude: this.userService.userAddress.locationCoordinates.lng,
                }
                this.calculateAndDisplayRoute()
              }
            },);
          }
          // this.getTravelTime()
          this.foundNearbyAddress = true;
          this.cdr.detectChanges();
        }
        clearInterval(timerUser);
      } else {
        let elapsedTime = new Date().getTime() - startTimer.getTime(); 
        if (elapsedTime > 5000) {
          firebase.auth().onAuthStateChanged((user: any) => {
            if (user) {
              this.userService.getCurrentUser(user.uid).subscribe((res: any) => {
                if(res.data()) {
                  if (!res.data()?.hasOwnProperty('firstName')) {
                    console.warn('complete sign up');
                  } else {
                    this.getCartDetails();
                    if(!this.userService.userAddress) {
                      this.getSavedAddresses();
                    } else {
                      this.userAddress = this.userService.userAddress
                      if(!this.userService.duration) {
                        var timer = setInterval(() => {
                          if(this.origin) {
                            clearInterval(timer)
                            this.destination = {
                              latitude: this.userService.userAddress.locationCoordinates.lat,
                              longitude: this.userService.userAddress.locationCoordinates.lng,
                            }
                            this.calculateAndDisplayRoute()
                          }
                        },);
                      }
                    }
                    this.userService.userData = res.data();
                  }
                }
              })
            } else {
              console.warn("User not logged in");
            }
          });
          clearInterval(timerUser);
        }
      }
    },);
  }

  getDiscounts() {
    this.discounts = [];
    this.userService.getActiveDiscounts().subscribe((res: any) => {
      res.forEach((discount: any) => {
        this.discounts.push(discount.data())
      });
    })
  }

  getSavedAddresses() {
    this.addressBookService.getAddresses().subscribe((res: any) => {
      res.forEach((address: any) => {
        this.savedAddress.push(address.data())
      });
      this.getGeolocation();
    })
  }

  applyDiscount(discountCode: string) {
    this.userService.applyDiscount(discountCode).then((res) => {
      this.getCartDetails();
      if(discountCode == 'TRYAAH') {
        this.deliveryPartnerFee = 0;
        // this.cartTotal = this.cartSubTotal + this.gstCharges + this.deliveryPartnerFee + 3
      }
    }, err => {
      console.warn(err);
      
    })
  }

  addInstructions(instruction: string) {
    this.showSpinner = true;
    this.userService.addInstruction(this.cartDetails.deliveryInstruction == instruction ? '' : instruction).then((res) => {
      this.getCartDetails();
      this.showSpinner = false;
    }, err => {
      this.showSpinner = false;
      console.warn(err);
    })
  }

  modifyTipAmount(tipAmount: number) {
    this.showSpinner = true;
    this.userService.addTip(this.cartDetails.tipAmount == tipAmount ? 0 : tipAmount).then((res) => {
      this.cartTotal = this.cartTotal + this.cartDetails.tipAmount == tipAmount ? 0 : tipAmount
      this.getCartDetails(this.cartDetails.tipAmount == tipAmount ? 0 : tipAmount);
      this.showSpinner = false;
    }, err => {
      this.showSpinner = false;
      console.warn(err);
    })
  }

  removeDiscount() {
    this.userService.applyDiscount(null).then((res) => {
      this.getCartDetails();
      // this.calculateAndDisplayRoute();
    }, err => {
      console.warn(err);
      
    })
  }

  getCartDetails(tipAmount?: number) {
    this.cartTotal = 0;
    this.cartSubTotal = 0;
    this.userService.getCartDetails().subscribe((res: any) => {
      this.cartDetails = res.data();
      if(this.cartDetails.items.length == 0) {
        this.cartDetails = 0;
      } else {
        this.cartDetails['id'] = res.id;
        this.origin = { latitude: parseFloat(this.cartDetails?.outletDetails?.outletCoordinates.outletLatitude), longitude: parseFloat(this.cartDetails?.outletDetails?.outletCoordinates.outletLongitude) }
        this.cartDetails?.items.forEach((item: any) => {
          this.cartSubTotal = this.cartSubTotal + (item.itemPrice * item.itemQuantity)
        });
        if(!this.cartDetails.couponApplied) {
          this.gstCharges = (parseFloat((this.cartSubTotal * 0.025).toFixed(2))) * 2;
          if (this.distanceInKm < 1) {
            this.deliveryPartnerFee = 5;
          } else if (this.distanceInKm >= 1.1 && this.distanceInKm <= 2.9) {
            this.deliveryPartnerFee = 9;
          } else if (this.distanceInKm >= 3.0 && this.distanceInKm <= 4.9) {
            this.deliveryPartnerFee = 19;
          } else {
            this.deliveryPartnerFee = 25;
          }
        } else if(this.cartDetails.couponApplied == 'TRYAAH') {
          this.deliveryPartnerFee = 0
        }
        this.cartTotal = this.cartSubTotal + this.deliveryPartnerFee + this.gstCharges + 3
        if(tipAmount) {
          this.cartTotal = this.cartTotal + tipAmount;
        }
        this.getTravelTime()
      }
    })
  }

  // getGeolocation(): Promise<{ latitude: number, longitude: number }> {
  //   return new Promise((resolve, reject) => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           const latitude = position.coords.latitude;
  //           const longitude = position.coords.longitude;
  //           this.center = {
  //             lat: position.coords.latitude,
  //             lng: position.coords.longitude,
  //           };
  //           resolve({ latitude, longitude });
  //           this.destination = { latitude, longitude }
  //           let startTime = new Date();
  //           const timer = setInterval(() => {
  //             if (this.origin && this.destination) {
  //               clearInterval(timer);
  //               this.calculateAndDisplayRoute()
  //             // this.cartTotal = this.cartSubTotal + this.gstCharges + this.deliveryPartnerFee + 3
  //             } else {
  //               let elapsedTime = new Date().getTime() - startTime.getTime();
  //               if (elapsedTime > 60000) {
  //                 clearInterval(timer);
  //               }
  //             }
  //           },);
  //         },
  //         (error) => {
  //           reject(error);
  //         }
  //       );
  //     } else {
  //       reject("Geolocation is not supported by this browser.");
  //     }
  //   });
  // }

  getGeolocation(): Promise<{ latitude: number, longitude: number }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            this.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            resolve({ latitude, longitude });
            this.destination = { latitude, longitude };

            // Check if there are saved addresses
            if (this.savedAddress.length > 0) {
              this.foundNearbyAddress = false;

              for (const address of this.savedAddress) {
                const distance = this.calculateDistance(
                  latitude,
                  longitude,
                  address.locationCoordinates.lat,
                  address.locationCoordinates.lng
                );

                if (distance < 0.2) { // 0.2 km = 200 meters
                  this.userAddress = address;
                  this.userService.userAddress = address
                  this.foundNearbyAddress = true;
                  break; // Exit the loop if a nearby address is found
                }
              }

              if (this.foundNearbyAddress) {
                this.calculateAndDisplayRoute(
                  this.userAddress.locationCoordinates.lat, 
                  this.userAddress.locationCoordinates.lng
                ); 
                return;
              }
            }

            // If no nearby address or no saved addresses, continue with existing process
            let startTime = new Date();
            const timer = setInterval(() => {
              if (this.origin && this.destination) {
                clearInterval(timer);
                this.calculateAndDisplayRoute();
              } else {
                let elapsedTime = new Date().getTime() - startTime.getTime();
                if (elapsedTime > 60000) {
                  clearInterval(timer);
                }
              }
            },);
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject("Geolocation is not supported by this browser.");
      }
    });
  }

  calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lon2 - lon1);   

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg:   
 number): number {
    return deg * (Math.PI / 180);   

  }

  getLocalityAndCityName() {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: this.center }, (results, status) => {
        if (status === 'OK' && results && results[0]) {
          const addressComponents = results[0].address_components;
          // Find locality
          const localityComponent = addressComponents.find(component =>
            component.types.includes('locality') || component.types.includes('sublocality_level_1')
          );
          this.localityName = localityComponent ? localityComponent.long_name : null;
          const cityComponent = addressComponents.find(component => {
            return component.types.includes('political') && 
                   !component.types.includes('sublocality') && 
                   !component.types.includes('sublocality_level_1') && 
                   !component.types.includes('sublocality_level_2');
          });
          this.cityName = cityComponent ? cityComponent.long_name : null;
          // Find city

          this.userAddress = {
            mapLocation: results[0].formatted_address
          };
          console.warn(this.userAddress);
          
          // Store both locality and city in localStorage
          // localStorage.setItem("LocationData", locationData); 
  
        } else {
          console.error('Geocoder failed due to: ' + status);
        }
      });
  }

  calculateAndDisplayRoute(destLat?: number, destLng?: number) {
    console.warn('calculate');
    
    const directionsService = new google.maps.DirectionsService();

    if (this.origin && this.destination) {
      // const originLatLng = new google.maps.LatLng(this.origin.latitude, this.origin.longitude);
      // const destinationLatLng = new google.maps.LatLng(this.destination.latitude, this.destination.longitude);
      const originLatLng = new google.maps.LatLng(this.origin.latitude, this.origin.longitude);
      const destinationLatLng = destLat && destLng 
      ? new google.maps.LatLng(destLat, destLng) 
      : new google.maps.LatLng(this.destination.latitude, this.destination.longitude);
      directionsService.route(
        {
          origin: originLatLng,
          destination: destinationLatLng,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response: any, status) => {
          try {
            if (status === 'OK') {
              // this.directionsResults = response;
              const route = response.routes[0];
              const duration = route.legs.reduce((acc: any, leg: any) => acc + leg.duration!.value, 0);
              const distance = route.legs.reduce((acc: any, leg: any) => acc + leg.distance!.value, 0);
              const hours = Math.floor(duration / 3600);
              const minutes = Math.floor((duration % 3600) / 60);
              this.travelTime = `${hours} hours ${minutes} minutes`;
              this.travelDistance = `${(distance / 1000).toFixed(2)} km`; 
              this.duration = duration
              this.showSpinner = false;
              this.getTravelTime()
              // this.travelTime = hours * 60 + minutes + 20
              // if(distance < 100) {
              //   console.warn('Delivery partner reached');
              // } else {
              const distanceInKm = distance / 1000;
              this.distanceInKm = distanceInKm
              this.deliveryPartnerFee = 0
              if (distanceInKm < 1) {
                this.deliveryPartnerFee = 5;
              } else if (distanceInKm >= 1.1 && distanceInKm <= 2.9) {
                this.deliveryPartnerFee = 9;
              } else if (distanceInKm >= 3.0 && distanceInKm <= 4.9) {
                this.deliveryPartnerFee = 19;
              } else {
                this.deliveryPartnerFee = 25;
              }
              this.cartTotal = this.cartSubTotal + this.deliveryPartnerFee + this.gstCharges + 3
              if(!this.foundNearbyAddress) {
                this.getLocalityAndCityName()
              }
              // }
            } else {
              console.error('Directions request failed due to ' + status);
            }
          } catch (error) {
            console.error('Error handling directions:', error);
            // Handle the error (e.g., display an error message to the user)
          }

        }
      );
    }

  }

  getTravelTime() {
    const hours = Math.floor(
      this.userService.duration
      ? this.userService.duration / 3600
      : this.duration / 3600
    );
    const minutes = Math.floor(
      this.userService.duration
      ? (this.userService.duration % 3600) / 60
      : (this.duration % 3600) / 60
    );    const itemCount = this.cartDetails?.items?.length || 0; 
    let additionalTime = 20; 
            if (itemCount >= 1 && itemCount <= 3) {
              additionalTime = 22;
            } else if (itemCount > 3 && itemCount <= 6) {
              additionalTime = 30;
            } else if (itemCount > 6 && itemCount <= 10) {
              additionalTime = 36;
            } else if (itemCount > 10) {
              additionalTime = 42;
            }
    this.adpTime = hours * 60 + minutes
    this.travelTime = hours * 60 + minutes + additionalTime
    if(this.duration) {
      this.userService.duration = this.duration;
    }
    this.userService.adpTime = this.adpTime;
  }

  initiatePayment() {
    var obj = {}
    if (this.userData) {
      obj = {
        name: this.userData.firstName + this.userData.lastName,
        email: this.userData.email,
        mobile: this.userData.mobileNumber
      }
    } else {
      obj = {
        name: 'User',
        email: 'user@adev.co.in',
        mobile: '9999999999'
      }
    }
    const amount = this.cartTotal * 100; // Amount in currency subunits. Here 1000 means 10.00 INR
    const key = 'rzp_live_0pr1DoN6E38yAo'; // Replace with your actual API key
    this.razorpayService.payWithRazorpay(amount, key, obj)
      .then((success: any) => {
        if (success) {
          console.log('Payment was successful');
          // Further actions for successful payment
          this.placeOrder();
          var obj = {
            notificationTitle: 'New Payment',
            notificationDescription: `Payment was done successfully by ${this.userData.email}.`,
            notificationTime: new Date().valueOf(),
            severity: "success"
          }
          this.userService.superAdminIds.forEach((admin) => {
            this.userService.sendNotification(admin, obj).then((res) => {
              // console.warn("notification sent");
            })
          })

        }
      })
      .catch((error: any) => {
        if (!error) {
          console.log('Payment failed');
          this.toastService.showError("Payment failed, please try again")
          // Further actions for failed payment
          var obj = {
            notificationTitle: 'New Payment',
            notificationDescription: `Payment of ${this.userData.email} is failed.`,
            notificationTime: new Date().valueOf(),
            severity: "Danger"
          }
          this.userService.superAdminIds.forEach((admin) => {
            this.userService.sendNotification(admin, obj).then((res) => {
              // console.warn("notification sent");
            })
          })

        }
      });
  }

  generateOrderId(): string {
    const userId = this.userData.id;
    const timestamp = Date.now();
    const randomNumber = Math.floor(1000 + Math.random() * 9000); // Generate a random 4-digit number
  
    // Concatenate the parts to create the order ID
    const orderId = (timestamp + userId)  + randomNumber.toString(); 
    return orderId;
  }

  placeOrder() {
    var orderId = this.generateOrderId();
    var resObj = {
      customerDetails: {
        customerName: this.userService.userData.firstName + ' ' + this.userService.userData.lastName,
        orderId: orderId,
        customerLocation: this.userAddress.locationCoordinates,
        customerId: this.userService.userData.id
      },
      orderType: 'aahaar',
      items: this.cartDetails.items,
      minOrderTime: Math.ceil(this.travelTime * 0.50),
      maxOrderTime: Math.ceil(this.travelTime - this.adpTime),
      orderPlacedAt: Date.now(),
      orderStatus: 'new',
    }
    var userObj = {
      outletDetails: {
        outletName: this.cartDetails.outletDetails.outletName,
        outletLocation: this.cartDetails.outletDetails.outletLocation,
        outletLocality: this.cartDetails.outletDetails.outletLocality,
        outletId: 'YGQeXWB6K8bZlU7Lz9Dz',
      },
      items: this.cartDetails.items,
      orderId: orderId,
      orderPlacedAt: Date.now(),
      orderDeliveryBy: Date.now() + Math.ceil(this.travelTime - this.adpTime) * 60 * 1000,
      orderStatus: 'new',
      cartTotal: this.cartTotal,
      deliveryLocation: this.userAddress.locationCoordinates,
    }
    // var obj = {
    //   notificationTitle: 'New Order',
    //   notificationDescription: `Order is placed successfully by ${this.userData.email}.`,
    //   notificationTime: new Date().valueOf(),
    //   severity: "success"
    // }
    this.userService.placeOrder(this.cartDetails, resObj).then((res) => {
      console.warn('placed successfully');
          this.userService.superAdminIds.forEach((admin) => {
      // this.userService.sendNotification(admin, obj).then((res) => {
      //   // console.warn("notification sent");
      // })
    })

    })
    
    this.userService.updateUserOrderHistory(userObj).then((res) => {
      console.warn('order history successfully');
    })

    this.userService.clearCart().then((res) => {
      this.toastService.showSuccess("Order Placed successfully")
      const queryParams = { activeScreen: 'home' }; 

      this.router.navigate([], { 
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
    })
  }

  navigateToAddressBook() {
    this.navigationService.lastVisitedPath = window.location.href;
    this.router.navigateByUrl('addressBook')
  }

  navigateToAddLocation() {
    this.router.navigateByUrl('addlocation')
  }

  showDialog() {
    this.visible = true;
  }

  addAddress() {
    this.showAddSection = !this.showContainer;
  }

  container() {
    this.showContainer = !this.showContainer;
  }
  addToCart() {
    this.showCartCount = !this.showCartCount;
  }
  addChickenToCart() {
    this.showChickenCartCount = !this.showChickenCartCount;
  }

  navigateToHome() {
    window.location.href = this.navigationService.lastVisitedPath
    // const queryParams = { activeScreen: 'home' };

    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: queryParams,
    //   queryParamsHandling: 'merge' // Preserve existing query parameters
    // });
  }


  itemQuantityCheck(itemName: any): number {
    if(this.cartDetails.items.length > 0) {
      const index = this.cartDetails?.items.findIndex((item: any) => item.itemName === itemName);
      return index != -1 ? this.cartDetails.items[index]['itemQuantity'] : 0;
    } else {
      return 0;
    }
  }

  changeQuantity(item: any, operator: any) {
    this.showSpinner = true;
    const uid = this.afAuth.authState.subscribe(user => {
      if (user) {
        const cartRef = this.fireStore.collection('users').doc(user.uid).collection('cart').doc('cartItems');
  
        cartRef.get().subscribe((doc: any) => {
          if (doc.exists) {
            const items = doc.data()?.items || [];
            const itemIndex = items.findIndex((i: any) => i.itemName === item);
  
            if (itemIndex !== -1) {
              if (operator === 'inc') {
                items[itemIndex].itemQuantity += 1;
              } else if (operator === 'dec') {
                if (items[itemIndex].itemQuantity > 1) {
                  items[itemIndex].itemQuantity -= 1;
                } else {
                  // Remove the item if quantity is 1 and operator is 'dec'
                  items.splice(itemIndex, 1);
                }
              }
  
              cartRef.update({ items: items }).then(() => {
                this.showSpinner = false;
                this.getCartDetails(); // Update cart details after change
              });
            }
          }
        });
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
