<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer vw-100 d-flex" [style.height.px]="screenHeight" [ngClass]="{'whiteBackground': activeScreen !== 'home'}">
    <app-nav *ngIf="showNav && isDesktop"></app-nav>
    <div *ngIf="isMobile" class="d-flex flex-column mobileAlignment h-100 w-100">
        <div class="mobileBody h-100 w-100">
            <div class="homeCompDiv w-100" *ngIf="activeScreen == 'home'">
                <app-outlet-show-case [userData]="userData" [userLocation]="userLocation"></app-outlet-show-case>
            </div>
            <div class="homeCompDiv w-100" *ngIf="activeScreen == 'orderHistory'">
                <app-order-history></app-order-history>
            </div>
            <div class="homeCompDiv w-100" *ngIf="activeScreen == 'cart'">
                <app-preview-cart [userData]="userData"></app-preview-cart>
            </div>
            <div class="homeCompDiv w-100" *ngIf="activeScreen == 'settings'">
                <app-user-settings-landing [userData]="userData"></app-user-settings-landing>
            </div>
            <div class="activeOrderDetailsSection" *ngIf="activeScreen == 'home' && showActionPanel" @fadeInOut (@fadeInOut.done)="animationDone($event)">
                <div class="orderDetailsContainer"> 
                <div class="innerContent">
                    <div class="orderDetails" *ngFor="let order of activeOrders" (click)="navigateToTracking(order)">
                      <div class="d-flex flex-column orderMetaInfo justify-content-center">
                        <span class="title">
                          {{ 
                            order.orderStatus === 'new' 
                              ? 'Order confirmed' 
                              : order.orderStatus === 'preparing' && !order.isPickedUp
                                ? 'Order is being prepared' 
                                : order.isPickedUp
                                  ? "Order is out for delivery" 
                                  : '' 
                          }}
                        </span>
                        <span class="text-muted orderFromTitle">Order from {{order.outletDetails.outletName}}</span>
                      </div>
                      <div class="etaSection d-flex flex-column justify-content-center align-items-center">
                        <span class="timerTxt">{{ order.deliveryCountdown }}</span> 
                        <span class="etaTimeLeft">Minutes</span>
                      </div>
                    </div>
                </div>
                </div>
              </div>
            <div class="bottomNav" *ngIf="activeScreen != 'cart' && showActionPanel"  @fadeInOut (@fadeInOut.done)="animationDone($event)">
                <div class="bottomNavContent">
                    <div class="bottomNavActiveBackground"
                        [ngStyle]="{'left': activeLeft + 'px', 'top': activeTop + 'px'}"></div>
                    <span [ngClass]="activeScreen === 'home' ? 'bottomNavItemActive' : 'bottomNavItem'"
                        data-screen="home"
                        (click)="updateActiveScreen('home', $event)">
                        <span 
                            #homeAnimIcon
                            class="d-flex homeIcon">
                        </span> 
                    </span>
                    <span [ngClass]="activeScreen === 'orderHistory' ? 'bottomNavItemActive' : 'bottomNavItem'"
                        data-screen="orderHistory"
                        (click)="updateActiveScreen('orderHistory', $event)">
                        <span 
                            #orderHistoryAnimIcon
                            class="d-flex homeIcon">
                        </span> 
                    </span>
                    <span [ngClass]="activeScreen === 'cart' ? 'bottomNavItemActive' : 'bottomNavItem'"
                        data-screen="cart"
                        (click)="updateActiveScreen('cart', $event)">
                        <span 
                            #cartIconContainer
                            class="d-flex homeIcon">
                        </span> 
                    </span>
                    <span [ngClass]="activeScreen === 'settings' ? 'bottomNavItemActive' : 'bottomNavItem'"
                        data-screen="settings"
                        (click)="updateActiveScreen('settings', $event)">
                        <span 
                            #settingsAnimIcon
                            class="d-flex homeIcon">
                        </span> 
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isMobile" class="d-flex justify-content-center align-items-center h-100 w-100 text-center">
        <h4>This application is designed to work on mobile devices.<br>kindly open this application on a mobile device.</h4>
    </div>
</div>
<p-dialog header="Sign Up" class="signUpModal" [modal]="true" [(visible)]="showCompleteRegModal" [draggable]="false"
    [closable]="false">
    <div class="mt-4">
        <span class="p-text-secondary">We are really happy to have you on board. Please fill in the following details to
            complete the sign up process.</span>
    </div>
    <form [formGroup]="userRegistrationForm">
        <div class="d-flex flex-column gap-3 my-4">
            <div class="d-flex flex-column gap-2">
                <label for="firstName">First Name</label>
                <input pInputText id="firstName" formControlName="firstName" />
            </div>
            <div class="d-flex flex-column gap-2">
                <label for="lastName">Last Name</label>
                <input pInputText id="lastName" formControlName="lastName" />
            </div>
            <div class="d-flex flex-column gap-2 mb-2">
                <label for="mobileNumber">Mobile Number</label>
                <input pInputText id="mobileNumber" formControlName="mobileNumber" />
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button label="Save" [disabled]="userRegistrationForm.invalid" (onClick)="updateUserDetails()" />
    </div>
</p-dialog>