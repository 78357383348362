import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  lastVisitedPath: any = ''

  constructor() { }
}
