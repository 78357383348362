<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer" [style.height]="'calc(' + screenHeight + 'px - 6.5rem)'">
    <div class="cartContainer gap-2 d-flex gap-3" *ngIf="!showContainer && cartDetails">
        <div class="toolBar w-100 d-flex justify-content-between">
            <div class="d-flex gap-2 justify-content-center align-items-center">
                <span class="pi pi-chevron-left" (click)="navigateToHome()"></span>
                <span class="cartIdentity">My Cart</span>
            </div>
        </div>
        <div class="addressCard vw-70 d-flex">
            <div class="addressInfo d-flex">
                <span class="address" *ngIf="!foundNearbyAddress">{{userAddress?.mapLocation}}</span>
                <span class="address d-flex flex-column gap-1" *ngIf="foundNearbyAddress">
                    <span class="addressName">{{userAddress?.receiverName}}</span>
                    <span>{{userAddress?.identityPoint}}</span>
                </span>
            </div>
            <div class="addressTimeline d-flex justify-content-end gap-4">
                <div class="timelineInfo d-flex flex-column ">
                    <span class="timeNumber">
                        {{travelTime}}
                    </span>
                    <span class="timeLimit d-flex justify-content-center">
                        mins
                    </span>
                </div>
                <span class="pi pi-chevron-right d-flex justify-content-center align-items-center"
                    (click)="navigateToAddressBook()"></span>
            </div>
        </div>
        <div class="accordionBody vw-70 d-flex justify-content-between">
            <div class="leftSidePanel justify-content-center gap-1">
                <div class="cartDiv d-flex gap-1 align-items-baseline">
                    <span class="outlet">{{cartDetails?.outletDetails?.outletName}}</span>
                    <span class="outletArea d-flex justify-content-center align-items-center">(
                        {{cartDetails?.outletDetails?.outletLocality}} )</span>
                </div>
                <div class="outletMeta" *ngFor="let item of cartDetails?.items">
                    <div class="item-row d-flex align-items-center">
                        <div class="itemDetails d-flex justify-content-between w-100">
                            <div class="d-flex align-items-baseline gap-2">
                                <img [src]="item.itemClassification === 'Veg' ? 'assets/icons/veg-icon.png' : 'assets/icons/non-veg-icon.png'"
                                    alt="Veg/Non-veg" class="vegIcon">
                                <div class="d-flex flex-column gap-1">
                                    <div class="itemName">{{ item.itemName }}</div>
                                    <span class="itemPrice">₹ {{ item.itemPrice }} /-</span>
                                </div>
                            </div>
                            <div class="itemQuantityPrice d-flex flex-column align-items-end">
                                <!-- <span class="item-quantity">x {{ item.itemQuantity }}</span> -->
                                <div class="d-flex align-items-center">
                                    <button type="button" class="btn btn-light" *ngIf="itemQuantityCheck(item.itemName) > 1" [disabled]="itemQuantityCheck(item.itemName) == 1"
                                    (click)="changeQuantity(item.itemName, 'dec')">
                                        <span *ngIf="itemQuantityCheck(item.itemName) > 1">-</span>
                                    </button>
                                    <button type="button" class="btn btn-light" *ngIf="itemQuantityCheck(item.itemName) == 1"
                                    (click)="changeQuantity(item.itemName, 'dec')">
                                    <span *ngIf="itemQuantityCheck(item.itemName) == 1">
                                        <i class="pi pi-trash"></i>
                                    </span>
                                    </button>
                                <span class="mx-2">{{itemQuantityCheck(item.itemName)}}</span>
                                <button type="button" class="btn btn-light"
                                    (click)="changeQuantity(item.itemName, 'inc')">+</button>
                                </div>
                                <div>
                                    ₹ {{ item.itemQuantity * item.itemPrice }} /-
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="rateMeta ">
                    <span class="iconStar">
                        <i class="pi pi-star-fill"></i>
                    </span>
                    <span class="ratingInfo">
                        4.0
                    </span>
                </div> -->
            </div>
            <!-- <div class="cartPanel d-flex justify-content-end gap-4">
                <span class="addCartBtn d-flex justify-content-center align-items-center">
                    <p-button class="cartCount">
                        <span class="pi pi-trash trashIcon" (click)="addChickenToCart()"></span>
                        <span class="countText">1</span>
                        <span class="pi pi-plus plusIcon"></span>
                    </p-button>
                </span>
                <span class="costSection d-flex justify-content-center align-items-center">₹ 225</span>
            </div> -->
        </div>
        <div class="offerField d-flex gap-3 flex-column">
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">Offers & Savings</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="accordionBody vw-70 d-flex justify-content-between"
                            *ngFor="let discountCoupon of discounts">
                            <!-- <div class="discountCards" *ngIf="let discount of discounts">
                                <div class="leftPanel">
                                    
                                </div>
                                <div class="applyPanel d-flex justify-content-end gap-4">
                                    <span class="addCartBtn d-flex justify-content-center align-items-center">
                                        <p-button class="cartCount" label="Apply">
                                        </p-button>
                                    </span>
                                </div>
                            </div> -->
                            <div class="leftPanel justify-content-center gap-1">
                                <div class="offerInfo d-flex gap-2">
                                    <span class="pi pi-tag d-flex justify-content-center align-items-center"></span>
                                    <span class="offer">{{discountCoupon.discountCode}}</span>
                                </div>
                                <div class="outletMeta d-flex gap-2">
                                    <div class="viewName">
                                        {{discountCoupon.discountDescription}}
                                    </div>
                                </div>
                            </div>
                            <div class="applyPanel d-flex justify-content-end gap-4">
                                <span class="addCartBtn d-flex justify-content-center align-items-center"
                                    *ngIf="discountCoupon.discountCode != cartDetails.couponApplied">
                                    <p-button class="cartCount" label="Apply"
                                        (onClick)="applyDiscount(discountCoupon.discountCode)"></p-button>
                                </span>
                                <span class="d-flex justify-content-center align-items-center"
                                    *ngIf="discountCoupon.discountCode == cartDetails.couponApplied">
                                    <p-button class="cartCount" [outlined]="false" label="Applied"
                                        (onClick)="removeDiscount()"></p-button>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">Delivery Instructions</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="accordionBody vw-70 d-flex gap-2">
                            <div class="leaveAtDoor d-flex flex-column gap-2 justify-content-center align-items-center" (click)="addInstructions('Leave at door')"
                                [ngClass]="{'activeInstruction': cartDetails.deliveryInstruction == 'Leave at door'}">
                                <span
                                    class="pi pi-building-columns d-flex justify-content-center align-items-center"></span>
                                <span class="instructionText text-center">Leave at door</span>
                            </div>
                            <div class="leaveAtDoor d-flex gap-2 flex-column justify-content-center align-items-center" (click)="addInstructions('Don\'t ring bell')"
                                [ngClass]="{'activeInstruction': cartDetails.deliveryInstruction == 'Don\'t ring bell'}">
                                <span class="pi pi-bell-slash d-flex justify-content-center align-items-center"></span>
                                <span class="instructionText text-center">Don't ring bell</span>
                            </div>
                            <div class="leaveAtDoor d-flex gap-2 flex-column justify-content-center align-items-center" (click)="addInstructions('Leave with security')"
                                [ngClass]="{'activeInstruction': cartDetails.deliveryInstruction == 'Leave with security'}">
                                <span class="pi pi-shield d-flex justify-content-center align-items-center"></span>
                                <span class="instructionText text-center">Leave with security</span>
                            </div>
                            <!-- <div class="leaveAtDoor d-flex gap-2 justify-content-center align-items-center"
                                (click)="addInstructions('')">
                                <span class="instructionText">X</span>
                            </div> -->
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">Add a tip for delivery partner</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="accordionBody vw-70 d-flex gap-2">
                            <div class="leaveAtDoor d-flex gap-2" (click)="modifyTipAmount(10)"
                                [ngClass]="{'activeInstruction': cartDetails.tipAmount == 10}">
                                <span class="instructionText">₹ 10</span>
                            </div>
                            <div class="leaveAtDoor d-flex gap-2" (click)="modifyTipAmount(20)"
                                [ngClass]="{'activeInstruction': cartDetails.tipAmount == 20}">
                                <span class="instructionText">₹ 20</span>
                            </div>
                            <div class="leaveAtDoor d-flex gap-2" (click)="modifyTipAmount(30)"
                                [ngClass]="{'activeInstruction': cartDetails.tipAmount == 30}">
                                <span class="instructionText">₹ 30</span>
                            </div>
                            <!-- <div class="leaveAtDoor d-flex gap-2" (click)="modifyTipAmount(0)">
                                <span class="instructionText">X</span>
                            </div> -->
                            <!-- <div class="otherBtn d-flex justify-content-center align-items-center">
                                <p-button (onClick)="showDialog()" label="others" />
                                <p-dialog header="Enter your amount: " [modal]="true" [(visible)]="visible"
                                    [style]="{ width: '25rem' }">
                                    <div class="flex align-items-center gap-3 mb-3">
                                        <input pInputText id="username" class="flex-auto" autocomplete="off" />
                                    </div>
                                </p-dialog>
                            </div> -->
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title d-flex flex-column">
                                <span class="price">Total Price <span class="includState">(incl.Charges &
                                        Taxes)</span></span>
                                <span class="totalPrice">₹ {{cartTotal}}</span>
                            </span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="accordionBody vw-70 d-flex gap-1 flex-column">
                            <div class="itemTotal d-flex justify-content-between">
                                <span class="itemText">Item Total</span>
                                <span class="itemPrice">₹ {{cartSubTotal}}</span>
                            </div>

                            <div class="itemTotal d-flex justify-content-between">
                                <span class="feeText">Delivery Partner Fee</span>
                                <span class="itemPrice">₹ {{deliveryPartnerFee}}</span>
                            </div>
                            <!-- <div class="itemTotal d-flex justify-content-between">
                                <span class="feeText">Delivery Partner Tip</span>
                                <span class="itemPrice">₹ 10</span>
                            </div> -->

                            <div class="itemTotal d-flex justify-content-between">
                                <span class="feeText">Platform Fee</span>
                                <span class="itemPrice">₹ 3.00</span>
                            </div>
                            <div class="itemTotal d-flex justify-content-between">
                                <span class="taxText">GST <span class="pi pi-info-circle"></span></span>
                                <span class="itemPrice">₹ {{gstCharges}}</span>
                            </div>
                            <div class="itemTotal d-flex justify-content-between">
                                <span class="taxText">TIP <span class="pi pi-info-circle"></span></span>
                                <span class="itemPrice">₹ {{cartDetails.tipAmount}}</span>
                            </div>

                            <div class="priceTotal d-flex justify-content-between">
                                <span class="priceText">Total Price</span>
                                <span class="itemPrice">₹ {{cartTotal}}</span>
                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <div class="policyInfo d-flex gap-2 flex-column">
                <span class="cancelPolicy">Cancellation Policy</span>
                <span class="policyText">Hope You help us to reduce wasting food by avoiding cancellations (
                    Cancellation Window 90 seconds).</span>
            </div>
        </div>
        <div class="itemBtn">
            <span class="placeOrder" *ngIf="userAddress?.identityPoint" (click)="placeOrder()"> Place Order - ₹ {{cartTotal}}</span>
            <span class="placeOrder" *ngIf="!userAddress?.identityPoint" (click)="navigateToAddLocation()">Add Location</span>
        </div>
    </div>
    <div class="emptyState d-flex flex-column align-items-center w-100" [style.height.px]="screenHeight"
        *ngIf="!cartDetails">
        <div class="backSection d-flex gap-2 justify-content-start align-items-center w-100">
            <span class="pi pi-chevron-left" (click)="navigateToHome()"></span>
            <span class="cartIdentity">My Cart</span>
        </div>
        <span class="emptyMsg">
            Empty Cart
        </span>
    </div>
</div>