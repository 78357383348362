<div class="mainContainer d-flex flex-column" [style.height.px]="screenHeight">
    <div class="toolBar w-100 d-flex justify-content-between">
        <div class="d-flex gap-2 justify-content-center align-items-center">
            <span class="pi pi-chevron-left" (click)="navigateToCart()"></span>
            <span class="cartIdentity">Add Location</span>
        </div>
    </div>
    <div class="mapContainer">
        <google-map [options]="mapOptions" (mapClick)="onMapClick($event)">
            <map-marker 
              [position]="{ lat: lat, lng: lng }" 
              [options]="markerOptions" 
              (dragend)="onMarkerDragEnd($event)">
            </map-marker>
          </google-map>
    </div>
    <div class="locateMeBtn" (click)="locateMe()">
        <span class="pi pi-map-marker"></span>
    </div>
    <div class="actionPanel" (click)="showSaveDialog()">
        <span class="placeOrder">Save Location</span>
    </div>
</div>

<p-dialog header="Save Location" class="signUpModal" [modal]="true" [(visible)]="showAddLocationForm" [draggable]="false"
    [closable]="false">
    <div class="mt-4">
        <span class="p-text-secondary">Please ensure to mark accurate pin point for seamless delivery experience.</span>
    </div>
    <form [formGroup]="addLocationForm">
        <div class="d-flex flex-column gap-3 my-4">
            <div class="d-flex flex-column gap-2">
                <label for="identityPoint">Flat No/Building/Floor</label>
                <input pInputText id="identityPoint" formControlName="identityPoint" />
            </div>
        </div>
        <div class="d-flex flex-column gap-3 my-4">
            <div class="d-flex flex-column gap-2">
                <label for="receiverName">Receiver Name</label>
                <input pInputText id="receiverName" formControlName="receiverName" />
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button label="Save" [disabled]="addLocationForm.invalid" (onClick)="saveLocation()" />
    </div>
</p-dialog>